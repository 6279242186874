import React from 'react';
import { GetServerSideProps, NextPage } from 'next';
import TileGrid from '../components/tile-grid';
import type { Tile } from '../components/item-tile';
import Layout from '../components/layout';
import useRoleInRequest from '../hooks/use-role-in-request';
import useUserSignature from '../hooks/use-user-signature';
import HomeMessageboard from '../components/home-messageboard';
import usePermissionInRequest from '../hooks/use-permission-in-request';

interface Props {
  userId: string;
  signature: string;
  isAdmin: boolean;
}

const IndexPage: NextPage<Props> = ({ userId, signature, isAdmin }) => {
  const adminTiles: Tile[] = [
    {
      title: 'Certified by Volvo',
      description: 'Retailer Pre-Owned sales support materials',
      imageUrl: '/tiles/retailer/certified-by-volvo.jpg',
      tileUrl: '/bookstore/certified-by-volvo',
    },
    {
      title: 'VCFS Retailer Guide and POS Materials',
      description: 'Volvo Cars Financial Services Retailer Guide',
      imageUrl: '/tiles/retailer/vcfs-and-retailer-guide.webp',
      tileUrl: '/vcfs-retailer-guide',
    },
    {
      title: 'Aftersales, Parts, and Service',
      description:
        'Retailer-only Service and Support Materials, Brochures, and Point of Sale items',
      imageUrl: '/tiles/retailer/aftersales-and-service-tile.jpeg',
      tileUrl: '/bookstore/aftersales-parts-and-service',
    },
    {
      title: 'Parts Return Materials',
      description: 'Parts Return Labels and Bill of Lading Forms',
      imageUrl: '/tiles/retailer/parts-return-materials.jpg',
      tileUrl: '/bookstore/parts-return-materials',
    },
    {
      title: 'TMA Materials',
      description: 'TMA Return Tags, Return Bags, and HazMat labels and kits',
      imageUrl: '/tiles/retailer/tma-materials.webp',
      tileUrl: '/bookstore/tma-materials',
    },
  ];

  const tiles: Tile[] = [
    {
      title: 'New User',
      description:
        'Helpful information for creating an account, navigating the site and making purchases',
      imageUrl: '/tiles/independent/new-user-tile.webp',
      tileUrl: '/new-users',
    },
    {
      title: 'VIDA',
      description:
        'Vehicle Information and Diagnostics for Aftersales Application – subscription and setup information',
      imageUrl: '/tiles/independent/vida-tile.webp',
      tileUrl: '/vida',
    },
    {
      title: 'Vehicle Emergency Response Guide',
      description:
        'Resource for first responders on the proper safety precaution for Volvo vehicles',
      imageUrl: '/tiles/independent/verg-tile.webp',
      tileUrl: '/vehicle-emergency-response',
    },
    {
      title: 'Volvo Wholesale Parts Hub',
      description: 'Learn about and order Genuine Volvo Parts',
      imageUrl: '/tiles/independent/wholesale-parts-hub-tile.webp',
      tileUrl: '/wholesale-parts-hub',
    },
    {
      title: 'Key Codes',
      description:
        'Registered Vehicle Security Professionals may obtain key cutting codes for any U.S. or Canada Volvo car model',
      imageUrl: '/tiles/independent/keycodes-tile.webp',
      tileUrl: '/keycodes',
      cy: 'keycode-link',
    },
    {
      title: 'Support',
      description:
        'FAQs and assistance with non-vehicle repair related site, application, and system inquiries',
      imageUrl: '/tiles/support.jpg',
      tileUrl: '/knowledgebase',
    },
    {
      title: 'Workshop Literature Library',
      description:
        'Access to all available Journals, Bulletins, SDS and Wiring Diagrams in a PDF format',
      imageUrl: '/tiles/workshop-literature-library.jpg',
      tileUrl: '/account/library',
    },
    {
      title: "Owner's Literature and Information",
      description:
        'Purchase Compliance Letter, Owner’s Manuals and Glove Box Materials',
      imageUrl: '/tiles/independent/owners-literature.webp',
      tileUrl: '/owners',
    },
    {
      title: 'Parts and Service Literature',
      description:
        'Purchase hard copies of Journals, Bulletins and Service Literature',
      imageUrl: '/tiles/independent/parts-and-service.webp',
      tileUrl: '/parts',
    },
    {
      title: 'Training Materials',
      description:
        'Select from a range of Technical Training materials including course books and online training courses for information purposes',
      imageUrl: '/tiles/independent/training-materials.webp',
      tileUrl: '/training',
    },
    {
      title: 'Resources',
      description:
        'Child Safety, Service Action List, Workshop Support and other important information and links',
      imageUrl: '/tiles/independent/resources-tile.webp',
      tileUrl: '/resources',
    },
    {
      title: 'Special Tools',
      description:
        'Portal for ordering the tools recommended for Volvo repairs',
      imageUrl: '/tiles/independent/specialtools-tile.webp',
      tileUrl:
        signature === ''
          ? 'https://www.volvospecialtools.com'
          : `https://www.volvospecialtools.com/index.cfm?event=sso&id=${userId}&hash=${signature.toUpperCase()}`,
    },
    {
      title: 'Bulk RMI Request',
      description: 'Bulk RMI request',
      imageUrl: '/tiles/bulk-rmi-request.jpg',
      tileUrl: '/account/bulk-rmi-request/new',
    },
  ];

  if (isAdmin) {
    tiles.push(...adminTiles);
  }

  return (
    <Layout
      description="Technical Information, Parts, and Owner's Materials"
      hero={true}
      heroTitle="Volvo Car Bookstore"
      heroSubtitle="Technical Information, Parts, and Owner's Materials"
    >
      <HomeMessageboard />
      <TileGrid tiles={tiles} />
    </Layout>
  );
};

/* eslint-disable react-hooks/rules-of-hooks */
export const getServerSideProps: GetServerSideProps = async ({ req }) => {
  const isRetailer = await useRoleInRequest(req, ['RETAILER']);
  const isVCFS = await useRoleInRequest(req, ['RETAILGUIDEVENDOR']);
  const isAdmin =
    (await usePermissionInRequest(req, 'system_admin')) ||
    (await usePermissionInRequest(req, 'internal_access'));
  const userSignature = await useUserSignature(req);
  /* eslint-enable react-hooks/rules-of-hooks */

  if (isRetailer) {
    return {
      redirect: {
        destination: '/retailer',
        permanent: false,
      },
    };
  }

  if (isVCFS) {
    return {
      redirect: {
        destination: '/vcfs-retailer-guide',
        permanent: false,
      },
    };
  }

  return {
    props: {
      userId: userSignature.userId,
      signature: userSignature.signature,
      isAdmin: isAdmin,
    },
  };
};

export default IndexPage;
